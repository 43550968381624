import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header" >
        <h1 className="texto bounce-in-fwd"><span>C</span><span>H</span><span>I</span><span>N</span><span>O</span> </h1>
      </header>

      <p className="object">
        Programador
        </p>
      <a
        className="link"
        href="https://www.twitter.com/chinoxb_"
      >
        Twitter
        </a>
    </div>
  );
}

export default App;
